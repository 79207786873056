import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../components/layout"
import { ComiteRegionalNav } from "../../components/menus/ComiteRegionalNav"

import "./style.scss"

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

const TEAM = {
  bureau: [
    {
      name: "Matthieu FRATH",
      role: "Président"
    },
    {
      name: "Nadège DELZONGLE",
      role: "Vice-présidente, PCRA"
    },
    {
      name: "Jean-Bernard DUVAL",
      role: "Vice-président"
    },
    {
      name: "Hervé PACIEL",
      role: "Vice-président, Trésorier adjoint"
    },
    {
      name: "Patrick KIEFER",
      role: "Trésorier"
    },
    {
      name: "Jocelyne PETIT",
      role: "Secrétaire Générale"
    },
    {
      name: "Tatiana MOYSES",
      role: "Secrétaire adjointe"
    },
  ],
  others: [
    "Lucie BALAZOT",
    "Gérard BRISSAUD",
    "Hélène DELMOTTE (Médecin)",
    "Jean-Pierre HERVIEU-BERGERON",
    "Roland LANGAS",
    "Jacky SOUDANT",
    "Guy STRAUMANN",
    "Camille WILLEM-HOELLINGER"
  ]
}

const Equipe: React.FC<Props> = ({ data }) => {
  const [team] = useState(TEAM)

  return (
    <Layout>
      <Helmet title="Le Comité Régional" />
      <div className="container comite-regional-index">
        <div className="row mt-3">
          <div className="secondary-col">
            <ComiteRegionalNav />
          </div>
          <div className="main-col">
            <h1>Le Comité régional</h1>

            <p>
              Le Comité Régional est l&#39;instance représentative du tir à l&#39;arc dans le Grand Est depuis le 1er janvier 2017 et officie en tant qu'organe déconcentré de la FFTA. Il est organisé autour d&#39;un Comité Directeur composé de 15 membres.
            </p>
            <p>
              Les membres actuellement en place ont été élus lors de l'Assemblée Générale Élective du 30 novembre 2024.
            </p>
            <p>
              Pour toute demande d'information, nous vous invitons à nous contacter via cette adresse : &nbsp;
              <a href="mailto:contact@tiralarc-grand-est.fr">contact@tiralarc-grand-est.fr</a>.
            </p>

            <p>
              <small>
                Sinon, en cas de demande spécifique, chaque membre du CRTA possède une adresse au format <i>prenom.nom@tiralarc-grand-est.fr</i>, sans accent ni majuscule.
              </small>
            </p>

            <h2>Le Bureau</h2>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">Poste</th>
                </tr>
              </thead>
              <tbody>
                {team.bureau.map(({ name, role }) => (
                  <tr>
                    <td>{name}</td>
                    <td>{role}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <h2>Les autres membres du Comité Directeur</h2>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                </tr>
              </thead>
              <tbody>
                {team.others.map(name => (
                  <tr>
                    <td>
                      {name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Equipe
