export type Commission = {
  name: string
  manager: string
  members?: string[]
  children?: Commission[]
  superName?: string
  info?: string
}

const data: Commission[] = [
  {
    name: "Arbitres",
    manager: "Nadège DELZONGLE (PCRA)",
    members: [
      "Lucie BALAZOT (adj. Lorraine)",
      "Tatiana MOYSES (adj. Alsace)",
    ],
  },
  {
    name: "Calendrier",
    manager: "Nadège DELZONGLE",
    members: [
      "Lucie BALAZOT",
    ],
  },
  {
    name: "Communication",
    manager: "Guy STRAUMANN",
    members: [
      "Hervé PACIEL",
      "Camille WILLEM-HOELLINGER",
    ],
  },
  {
    name: "Formation",
    manager: "Jean-Pierre HERVIEU-BERGERON",
    members: ["Lucie BALAZOT"],
  },
  {
    name: "Labels",
    manager: "Gérard BRISSAUD",
    members: ["Jean-Pierre HERVIEU-BERGERON"],
    info: "1 référent par département",
  },
  {
    name: "Licences",
    manager: "Nadège DELZONGLE",
  },
  {
    name: "Médicale",
    manager: "Hélène DELMOTTE",
  },
  {
    name: "Sport/Santé",
    manager: "Roland LANGAS",
  },
  {
    name: "Commission sportive",
    manager: "Jean-Bernard DUVAL",
    superName: "Sous-commissions",
    children: [
      {
        name: "Adultes",
        manager: "Hervé PACIEL",
        info: "Salle, TAE, DR",
        members: ["Jacky SOUDANT"],
      },
      {
        name: "Jeunes",
        manager: "Camille WILLEM-HOELLINGER",
      },
      {
        name: "Parcours",
        manager: "Tatiana MOYSES",
        info: "Campagne, 3D, Nature",
        members: ["Roland LANGAS"],
      },
      {
        name: "Para tir à l'arc",
        manager: "Jocelyne PETIT",
        info: "FFH et FFSA",
      },
      {
        name: "Traditions",
        manager: "Jacky SOUDANT",
        info: "Beursault, Bouquet Provincial",
      },
      {
        name: "UNSS",
        manager: "Jean-Bernard DUVAL",
      },
    ],
  },
]

export default data
