export interface Document {
  name: "Archers pré-inscrits" | "Archers sélectionnés" | "Candidature" | "Mandat" | "Répartition" | "Pré-inscription" | "Résultats" | "Paiement"
  link: string
  date: string
  type: "PDF" | "Formulaire en ligne"
  important: boolean
}

export interface Quota {
  name: string
  shortname: string
  value: number
  registered: number
}

export interface Championnat {
  name: string
  shortname: string
  season: string
  place: string
  dateStart: string
  dateEnd: string | null
  documents: Document[]
  quotas: Quota[] | null
  updateDate: string
  default: boolean
}

const championnats: Championnat[] = [
  {
    name: "Salle 2025",
    shortname: "salle-jeunes",
    season: "2025",
    place: "St-Julien-les-Villas (10)",
    dateStart: "2025-02-01",
    dateEnd: "2025-02-02",
    documents: [
      {
        name: "Mandat",
        link: "/vie-sportive/mandats/2025121.pdf",
        date: "2024-12-21",
        type: "PDF",
        important: true,
      },
      {
        name: "Pré-inscription",
        link: "https://forms.gle/xsveMCDGL2Cz7oVu8",
        date: "2024-12-21",
        type: "Formulaire en ligne",
        important: true,
      },
      {
        name: "Archers pré-inscrits",
        link: "https://cloud.tiralarc-grand-est.fr/s/gbJewktAsnSxKwN",
        date: "",
        type: "PDF",
        important: true,
      },
      {
        name: "Paiement",
        link: "https://www.helloasso.com/associations/comite-regional-de-tir-a-l-arc-du-grand-est/evenements/cr-salle-2025",
        date: "2025-01-21",
        type: "Formulaire en ligne",
        important: true,
      },
    ],
    quotas: [
      {
        name: "U11 Femme Arc classique",
        shortname: "U11FCL",
        value: 4,
        registered: 3,
      },
      {
        name: "U11 Homme Arc classique",
        shortname: "U11HCL",
        value: 4,
        registered: 8,
      },
      {
        name: "U13 Femme Arc classique",
        shortname: "U13FCL",
        value: 8,
        registered: 7,
      },
      {
        name: "U13 Homme Arc classique",
        shortname: "U13HCL",
        value: 8,
        registered: 12,
      },
      {
        name: "U15 Femme Arc classique",
        shortname: "U15FCL",
        value: 16,
        registered: 14,
      },
      {
        name: "U15 Homme Arc classique",
        shortname: "U15HCL",
        value: 16,
        registered: 14,
      },
      {
        name: "U18 Femme Arc classique",
        shortname: "U18FCL",
        value: 16,
        registered: 20,
      },
      {
        name: "U18 Homme Arc classique",
        shortname: "U18HCL",
        value: 16,
        registered: 21,
      },
      {
        name: "U21 Femme Arc classique",
        shortname: "U21FCL",
        value: 8,
        registered: 9,
      },
      {
        name: "U21 Homme Arc classique",
        shortname: "U21HCL",
        value: 8,
        registered: 14,
      },
      {
        name: "U13/U15 Femme Arc à poulies",
        shortname: "U15FCO",
        value: 4,
        registered: 0,
      },
      {
        name: "U13/U15 Homme Arc à poulies",
        shortname: "U15HCO",
        value: 4,
        registered: 0,
      },
      {
        name: "U18/U21 Femme Arc à poulies",
        shortname: "U21FCO",
        value: 4,
        registered: 1,
      },
      {
        name: "U18/U21 Homme Arc à poulies",
        shortname: "U21HCO",
        value: 4,
        registered: 2,
      },
      {
        name: "U18 Femme Arc nu",
        shortname: "U18FBB",
        value: 4,
        registered: 5,
      },
      {
        name: "U18 Homme Arc nu",
        shortname: "U18HBB",
        value: 4,
        registered: 6,
      },
      {
        name: "Sénior 1 Femme Arc classique",
        shortname: "S1FCL",
        value: 8,
        registered: 16,
      },
      {
        name: "Sénior 1 Homme Arc classique",
        shortname: "S1HCL",
        value: 16,
        registered: 20,
      },
      {
        name: "Sénior 2 Femme Arc classique",
        shortname: "S2FCL",
        value: 8,
        registered: 16,
      },
      {
        name: "Sénior 2 Homme Arc classique",
        shortname: "S2HCL",
        value: 16,
        registered: 24,
      },
      {
        name: "Sénior 3 Femme Arc classique",
        shortname: "S3FCL",
        value: 4,
        registered: 3,
      },
      {
        name: "Sénior 3 Homme Arc classique",
        shortname: "S3HCL",
        value: 8,
        registered: 9,
      },
      {
        name: "Sénior 1 Femme Arc à poulies",
        shortname: "S1FCO",
        value: 4,
        registered: 4,
      },
      {
        name: "Sénior 1 Homme Arc à poulies",
        shortname: "S1HCO",
        value: 8,
        registered: 8,
      },
      {
        name: "Sénior 2 Femme Arc à poulies",
        shortname: "S2FCO",
        value: 4,
        registered: 6,
      },
      {
        name: "Sénior 2 Homme Arc à poulies",
        shortname: "S2HCO",
        value: 16,
        registered: 17,
      },
      {
        name: "Sénior 3 Femme Arc à poulies",
        shortname: "S3FCO",
        value: 4,
        registered: 4,
      },
      {
        name: "Sénior 3 Homme Arc à poulies",
        shortname: "S3HCO",
        value: 8,
        registered: 9,
      },
      {
        name: "Scratch Femme Arc nu",
        shortname: "SFBB",
        value: 8,
        registered: 10,
      },
      {
        name: "Scratch Homme Arc nu",
        shortname: "SHBB",
        value: 16,
        registered: 16,
      },
    ],
    updateDate: "2024-12-21",
    default: true,
  },
  // {
  //   name: "Salle Adultes 2025",
  //   shortname: "salle-adultes",
  //   season: "2025",
  //   place: "Troyes",
  //   dateStart: "2025-02-02",
  //   dateEnd: "2025-02-02",
  //   documents: [
  //     // {
  //     //   name: "Mandat",
  //     //   link: "/vie-sportive/mandats/64682.pdf",
  //     //   date: "2024-12-20",
  //     //   type: "PDF",
  //     //   important: true,
  //     // },
  //     {
  //       name: "Pré-inscription",
  //       link: "https://forms.gle/xsveMCDGL2Cz7oVu8",
  //       date: "2024-12-21",
  //       type: "Formulaire en ligne",
  //       important: true,
  //     },
  //     {
  //       name: "Archers pré-inscrits",
  //       link: "https://cloud.tiralarc-grand-est.fr/s/gbJewktAsnSxKwN",
  //       date: "",
  //       type: "PDF",
  //       important: true,
  //     },
  //     // {
  //     //   name: "Paiement",
  //     //   link: "https://www.helloasso.com/associations/comite-regional-de-tir-a-l-arc-du-grand-est/evenements/inscription-cr-salle-2024-a-mulhouse",
  //     //   date: "2024-01-22",
  //     //   type: "Formulaire en ligne",
  //     //   important: true,
  //     // },
  //   ],
  //   quotas: [
  //     {
  //       name: "Sénior 1 Femme Arc classique",
  //       shortname: "S1FCL",
  //       value: 8,
  //       registered: 16,
  //     },
  //     {
  //       name: "Sénior 1 Homme Arc classique",
  //       shortname: "S1HCL",
  //       value: 16,
  //       registered: 20,
  //     },
  //     {
  //       name: "Sénior 2 Femme Arc classique",
  //       shortname: "S2FCL",
  //       value: 8,
  //       registered: 16,
  //     },
  //     {
  //       name: "Sénior 2 Homme Arc classique",
  //       shortname: "S2HCL",
  //       value: 16,
  //       registered: 24,
  //     },
  //     {
  //       name: "Sénior 3 Femme Arc classique",
  //       shortname: "S3FCL",
  //       value: 4,
  //       registered: 3,
  //     },
  //     {
  //       name: "Sénior 3 Homme Arc classique",
  //       shortname: "S3HCL",
  //       value: 8,
  //       registered: 9,
  //     },
  //     {
  //       name: "Sénior 1 Femme Arc à poulies",
  //       shortname: "S1FCO",
  //       value: 4,
  //       registered: 4,
  //     },
  //     {
  //       name: "Sénior 1 Homme Arc à poulies",
  //       shortname: "S1HCO",
  //       value: 8,
  //       registered: 8,
  //     },
  //     {
  //       name: "Sénior 2 Femme Arc à poulies",
  //       shortname: "S2FCO",
  //       value: 4,
  //       registered: 6,
  //     },
  //     {
  //       name: "Sénior 2 Homme Arc à poulies",
  //       shortname: "S2HCO",
  //       value: 16,
  //       registered: 17,
  //     },
  //     {
  //       name: "Sénior 3 Femme Arc à poulies",
  //       shortname: "S3FCO",
  //       value: 4,
  //       registered: 4,
  //     },
  //     {
  //       name: "Sénior 3 Homme Arc à poulies",
  //       shortname: "S3HCO",
  //       value: 8,
  //       registered: 9,
  //     },
  //     {
  //       name: "Scratch Femme Arc nu",
  //       shortname: "SFBB",
  //       value: 8,
  //       registered: 10,
  //     },
  //     {
  //       name: "Scratch Homme Arc nu",
  //       shortname: "SHBB",
  //       value: 16,
  //       registered: 16,
  //     },
  //   ],
  //   updateDate: "2024-02-01",
  //   default: true,
  // },
  // {
  //   name: "Beursault 2024",
  //   shortname: "beursault",
  //   season: "2024",
  //   place: "Brienne - Châlons - Éclaron - Einville - Fismes - Mont l'Étroit",
  //   dateStart: "2024-04-14",
  //   dateEnd: "2024-09-08",
  //   documents: [
  //     {
  //       name: "Résultats",
  //       link: "https://cloud.tiralarc-grand-est.fr/s/BdqHfnpkW5DzqRa",
  //       date: "2024-09-09",
  //       type: "PDF",
  //       important: true,
  //     },
  //   ],
  //   quotas: null,
  //   updateDate: "2024-09-09",
  //   default: true,
  // },
  {
    name: " - Demande d'organisation -",
    shortname: "demande-orga",
    season: "2026",
    place: "",
    dateStart: "2025-09-01",
    dateEnd: "2026-08-31",
    documents: [
      {
        name: "Candidature",
        link: "https://forms.gle/nqbuaM5sg4v7roHQ9",
        date: "2024-12-02",
        type: "Formulaire en ligne",
        important: true,
      },
    ],
    quotas: null,
    updateDate: "2024-12-21",
    default: false,
  },
]

export default championnats
